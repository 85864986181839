<template>
  <div>
    <div class="main_intro">
      <div class="main_intro_inner">
        <div class="main_intro_head">
          <img :src="iconConfirmScreenUrl" alt="">
        </div>
        <p class="main_intro_desc" v-html="$t('Checkin.this-site-uses-a-layout-designed-for-smartphones')"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Landing",
  data() {
    return {
      iconConfirmScreenUrl : require('@/assets/img/icon-checkin-change.png'),
    }
  },
}
</script>

<style scoped>

</style>